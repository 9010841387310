import Cookies from 'js-cookie';
import Toastrs from "@/utils/Toastrs";
export default {
  computed: {
    appid() {
      return this.$store.state.appid
    },
    channels() {
      return this.$store.state.channels
    },
    redirect_uri() {
      return this.$store.state.redirect_uri
    },
    login_url() {
      return this.$store.state.login_url
    },
    user_type() {
      return this.$store.state.user_type
    },
    template() {
      return this.$store.state.template
    },
  },
  methods: {
    loginSuccess(token, {openid}={}) {
      console.log('loginSuccess token', token)
      if(token){
        Toastrs.success('登录成功')
      }
      this.$store.dispatch('removelocalStorage')

      let redirect_uri = this.redirect_uri
      let token_key = this.$route.query.tokenkey || 'sso_token' 
      console.log('loginSuccess token_key', token_key)
      Cookies.set(`token_${this.$store.state.appid}`, token, { domain: "resthub.link" });
      Cookies.set(token_key , token, { domain: "resthub.link" });
      if(openid){
        Cookies.set(`openId_${this.$store.state.appid}`, openid, { domain: "resthub.link" });
      }
     
      setTimeout(()=>{
        if (redirect_uri.indexOf("resthub.link") == -1) {
          window.location.replace(
            redirect_uri +
            `${redirect_uri.indexOf("?") != -1 ? "&" : "?"}X-Auth-Utoken=${token}${openid ? `&openId=${openid}`:''}`
          );
        } else {
          window.location.replace(redirect_uri);
        }
      }, 1000)
    }
  },
}